import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { graphql, Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";

let similar_firms_master = [
    { "slug": "Talentica", "name": "Talentica Software", "tagline": "Startup experts", "logo": "Talentica-Logo.svg" },
    { "slug": "theintellify", "name": "the intellify", "tagline": "AR | VR | AR Filters | WebAR", "logo": "the-intellify.svg" },
    { "slug": "A3logics", "name": "A3logics", "tagline": "IT Consulting ", "logo": "a3logics.png" },
]

const ProviderProfile = ({ pageContext }) => {
    let firm = pageContext.record;
    let similar_firms = similar_firms_master.filter(function( f ) {
        return firm.name !== f.name;
      });
    return (
        <>
            <PageWrapper headerConfig={{  }}>
                <div className="bg-default-2 pt-16 pt-lg-22 pb-lg-27">
                    <div className="container">
                        {/* <!-- back Button --> */}
                        <div className="row justify-content-center">
                            <div className="col-12 mt-13 dark-mode-texts">
                                <div className="mb-9">
                                    <Link to="/#" className="d-flex align-items-center ml-4">
                                        <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                                        <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                                            Back
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* <!-- back Button End --> */}
                        <div className="row ">
                            {/* <!-- Company Profile --> */}
                            <div className="col-12 col-xl-9 col-lg-8">
                                <div className="bg-white rounded-4 pt-11 shadow-9">
                                    <div className="d-xs-flex align-items-center pl-xs-12 mb-8 text-center text-xs-left">
                                        <Link to="/#" className="mr-xs-7 mb-5 mb-xs-0">
                                            <img className="square-72 rounded-6" src={"/assets/sellers/logos/" + firm.logo} alt="" />
                                        </Link>
                                        <div className="">
                                            <h2 className="mt-xs-n5">
                                                <Link
                                                    to="/#"
                                                    className="font-size-6 text-black-2 font-weight-semibold"
                                                >
                                                    {firm.name}
                                                </Link>
                                            </h2>
                                            <span className="mb-0 text-gray font-size-4">
                                                {firm.tagline}
                                            </span>
                                        </div>
                                    </div>
                                    {/* <!-- Tab Section Start --> */}
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="company">
                                        <Nav
                                            className="nav border-bottom border-mercury pl-12"
                                            role="tablist"
                                        >
                                            <li className="tab-menu-items nav-item pr-12">
                                                <Nav.Link
                                                    eventKey="company"
                                                    className="text-uppercase font-size-3 font-weight-bold text-default-color py-3 px-0"
                                                >
                                                    Company
                                                </Nav.Link>
                                            </li>
                                            {/*
                                            <li className="tab-menu-items nav-item pr-12">
                                                <Nav.Link
                                                    eventKey="projects"
                                                    className="text-uppercase font-size-3 font-weight-bold text-default-color py-3 px-0"
                                                >
                                                    Projects
                                                </Nav.Link>
                                            </li>
                                            */}
                                        </Nav>
                                        {/* <!-- Tab Content --> */}
                                        <Tab.Content className="pl-12 pt-10 pb-7 pr-12 pr-xxl-24">
                                            <Tab.Pane eventKey="company">
                                                {/* <!-- Middle Body Start --> */}
                                                <div className="row">
                                                    {/* <!-- Single Widgets Start --> */}
                                                    <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                                                        <div className="mb-8">
                                                            <p className="font-size-4">Company size</p>
                                                            <h5 className="font-size-4 font-weight-semibold text-black-2">
                                                                {firm.headcount + " employees"}
                                                            </h5>
                                                        </div>
                                                        <div className="mb-8">
                                                            <p className="font-size-4">Est. Since</p>
                                                            <h5 className="font-size-4 font-weight-semibold text-black-2">
                                                                {firm.started}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    {/* <!-- Single Widgets End --> */}
                                                    {/* <!-- Single Widgets Start --> */}
                                                    <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                                                        <div className="mb-8">
                                                            <p className="font-size-4">Type of corporation</p>
                                                            <h5 className="font-size-4 font-weight-semibold text-black-2">
                                                                B2B
                                                            </h5>
                                                        </div>
                                                        <div className="mb-8">
                                                            <p className="font-size-4">Social Media</p>
                                                            <div className="icon-link d-flex align-items-center">
                                                                <a
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href={firm.socialmedia.linkedin}
                                                                    className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                                                                >
                                                                    <i className="fab fa-linkedin-in"></i>
                                                                </a>
                                                                <a
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href={firm.socialmedia.facebook}
                                                                    className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                                                                >
                                                                    <i className="fab fa-facebook-f"></i>
                                                                </a>
                                                                <a
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href={firm.socialmedia.twitter}
                                                                    className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                                                                >
                                                                    <i className="fab fa-twitter"></i>
                                                                </a>
                                                                <a
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href={firm.website}
                                                                    className="text-smoke circle-32 bg-concrete mr-5 hover-bg-green"
                                                                >
                                                                    <i className="fa fa-globe"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- Single Widgets End --> */}
                                                    {/* <!-- Single Widgets Start --> */}
                                                    <div className="col-12 col-lg-4 col-md-4 col-xs-6">
                                                        <div className="mb-8">
                                                            <p className="font-size-4">Location</p>
                                                            <h5 className="font-size-4 font-weight-semibold text-black-2">
                                                                {firm.locations}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    {/* <!-- Single Widgets End --> */}
                                                </div>
                                                {/* <!-- Middle Body End --> */}
                                                {/* <!-- Excerpt Start --> */}
                                                <h4 className="font-size-6 mb-7 text-black-2 font-weight-semibold">
                                                    {"About " + firm.name}
                                                </h4>
                                                <div className="pt-5 ">
                                                    {
                                                        firm.overview.map(function (para, index) {
                                                            return (
                                                                <p key={index}>{para}</p>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {/* <!-- Excerpt End --> */}
                                                {/* <!-- Excerpt Start --> */}
                                                <h5 className="font-size-5 mb-7 text-black-2 font-weight-semibold">
                                                    Domains, Tools & Technologies
                                                <div className="pt-5 ">
                                                        {
                                                            Object.entries(firm.keywords).map(function (domain, index) {
                                                                console.log(domain)
                                                                let d = domain[0];
                                                                if (d === "AR_VR") d = "AR / VR";
                                                                if (d === "AI") d = "AI / ML";
                                                                if (domain[0] === "" || domain[1][0] === "")
                                                                    return (<React.Fragment key={index}></React.Fragment>)
                                                                else
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <h6 className="font-size-4 mb-1 mt-1 font-weight-semibold" key={index} style={{ marginBottom: "0em", color: "#555" }}>{d}</h6>
                                                                            {
                                                                                domain[1].map(function (keyword, ind) {
                                                                                    return (
                                                                                        <span key={ind} style={{ fontWeight: "400", fontSize: '13px', padding: "4px 8px", color: "#0c1012 !important", backgroundColor: "rgba(176, 213, 232, 0.15)", borderRadius: "4px", marginRight: "4px" }}>{keyword}</span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                            })
                                                        }
                                                    </div>
                                                </h5>
                                                {/* <!-- Excerpt End --> */}
                                            </Tab.Pane>
                                        </Tab.Content>
                                        {/* <!-- Tab Content End --> */}
                                        {/* <!-- Tab Section End --> */}
                                    </Tab.Container>
                                </div>
                            </div>
                            {/* <!-- Company Profile End --> */}
                            {/* <!-- Sidebar --> */}
                            <div className="col-12 col-xl-3 col-lg-4 col-md-5 col-sm-6">
                                <div className="pt-11 pt-lg-0 pl-lg-5">
                                    <h4 className="font-size-6 font-weight-semibold mb-0">
                                        Similar Companies
                                    </h4>
                                    <ul className="list-unstyled">
                                        {
                                            similar_firms.map(function (f, index) {
                                                return (
                                                    <li key={index} className="border-bottom">
                                                        <Link to={"/provider/" + f.slug} className="media align-items-center py-9">
                                                            <div className="mr-7 p-2" style={{backgroundColor:"white"}}>
                                                                <img
                                                                    className="square-72 rounded-5"
                                                                    src={"/assets/sellers/logos/" + f.logo}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="mt-n4">
                                                                <h4 className="mb-0 font-size-5 font-weight-semibold">
                                                                    {f.name}
                                                                </h4>
                                                                <p className="mb-0 font-size-4">{f.tagline}</p>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            {/* <!-- end Sidebar --> */}
                        </div>
                    </div>
                </div>
            </PageWrapper>
        </>
    );
};
export default ProviderProfile;
